var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 12 } },
            [
              _c(
                "div",
                { staticClass: "add-new-card gx-text-right" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openNewCardModal },
                    },
                    [_vm._v("\n          Add new Card\n        ")]
                  ),
                ],
                1
              ),
              _c(
                "a-card",
                {
                  staticClass: "gx-card-full",
                  attrs: { title: "Active Cards" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "credit-cards" },
                    _vm._l(_vm.cards, function (card, index) {
                      return _c("stripe-card-preview", {
                        key: index,
                        attrs: { card: card },
                        on: {
                          remove: _vm.removeCard,
                          setDefault: _vm.setDefault,
                        },
                      })
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 12 } },
            [
              _c(
                "a-card",
                {
                  staticClass: "gx-card-table-full gx-table-responsive",
                  attrs: { title: "Manage My Payments History" },
                },
                [_c("member-billing-history-table")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "member-welcome-modal" },
        [
          _c(
            "a-modal",
            {
              staticClass: "welcome-modal",
              attrs: { "mask-closable": false },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "a-carousel",
                {
                  attrs: {
                    "after-change": _vm.onMemberModalChange,
                    arrows: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.displayMemberPreviousBtn
                        ? {
                            key: "prevArrow",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-slick-arrow",
                                    staticStyle: {
                                      "z-index": "1",
                                      left: "8px",
                                    },
                                  },
                                  [_vm._v("\n            Previous\n          ")]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                      _vm.displayMemberNextBtn
                        ? {
                            key: "nextArrow",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-slick-arrow",
                                    staticStyle: { right: "8px" },
                                  },
                                  [_vm._v("Next")]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-pt-5",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("a-col", { attrs: { lg: 14, md: 24 } }, [
                            _c("h1", [_vm._v("WELCOME")]),
                            _c("h2", [_vm._v("To Subsapp.com")]),
                            _c("p", [
                              _vm._v(
                                "You have successfully logged in as a Member."
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 10, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slide1.png"),
                                alt: "slide1",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-pt-5",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("h3", [
                              _vm._v(
                                "As a member you can check your schedule."
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slide2.png"),
                                alt: "slide2",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-pt-4",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("h4", [
                              _vm._v(
                                "\n                As a member you may join a club and accept/decline club and\n                event invitations.\n              "
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slide3.png"),
                                alt: "slide3",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-pb-5",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("h3", [
                              _vm._v(
                                "Subsapp.com helps you check team status."
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slider4.png"),
                                alt: "slide4",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        { attrs: { type: "flex", align: "middle" } },
                        [
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("p", [
                              _vm._v(
                                "\n                Subsapp.com operates in partnership with Stripe. Meaning\n                payments are easy and secure.\n              "
                              ),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slider5.png"),
                                alt: "slide5",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "a-row",
                        {
                          staticClass: "gx-pt-5",
                          attrs: { type: "flex", align: "middle" },
                        },
                        [
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("h2", [_vm._v("Enter your")]),
                            _c("p", [
                              _vm._v("card details and become a member."),
                            ]),
                          ]),
                          _c("a-col", { attrs: { lg: 12, md: 24 } }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/slider6.png"),
                                alt: "slide6",
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              !_vm.displayMemberNextBtn
                ? _c(
                    "div",
                    {
                      staticClass: "custom-slick-arrow click-btn custom-btn",
                      on: { click: _vm.updateIsloggedIn },
                    },
                    [_vm._v("\n        Click here\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.inviteModalVisible
        ? _c(
            "div",
            [
              _vm.invite
                ? _c(
                    "a-modal",
                    {
                      attrs: {
                        title: "New Invitation",
                        "mask-closable": false,
                      },
                      model: {
                        value: _vm.inviteModalVisible,
                        callback: function ($$v) {
                          _vm.inviteModalVisible = $$v
                        },
                        expression: "inviteModalVisible",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "\n        You have a new invitation from\n        "
                        ),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.invite ? _vm.invite.club.club_name : "")
                          ),
                        ]),
                        _vm._v(". Would you like to accept it?\n      "),
                      ]),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", disabled: _vm.loader },
                              on: {
                                click: function ($event) {
                                  return _vm.actionInvitation(
                                    _vm.invite.club_id,
                                    _vm.invite.id,
                                    "accept",
                                    _vm.invite.cmId
                                  )
                                },
                              },
                            },
                            [_vm._v("Accept")]
                          ),
                          _c(
                            "a-button",
                            {
                              attrs: { disabled: _vm.loader },
                              on: {
                                click: function ($event) {
                                  return _vm.actionInvitation(
                                    _vm.invite.club_id,
                                    _vm.invite.id,
                                    "decline",
                                    _vm.invite.cmId
                                  )
                                },
                              },
                            },
                            [_vm._v("Decline")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("stripe-new-card-modal", {
        attrs: { visible: _vm.newCardModalVisible },
        on: { token: _vm.saveCard, close: _vm.closeNewCardModalVisible },
      }),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { xs: 24, sm: 24, md: 12 } },
            [
              _vm.error_msg
                ? _c("a-alert", {
                    staticClass: "margin_top",
                    attrs: {
                      message: "Error",
                      description:
                        "Something went wrong, we could not add this card.",
                      type: "error",
                      closable: "",
                      "show-icon": "",
                    },
                    on: { close: _vm.close_alert },
                  })
                : _vm._e(),
              _c("div", { staticClass: "add-new-card gx-text-right" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }