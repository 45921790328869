<template>
  <n-page>
    <a-row>
      <a-col :xs="24" :sm="24" :md="12">
        <div class="add-new-card gx-text-right">
          <a-button type="primary" @click="openNewCardModal">
            Add new Card
          </a-button>
        </div>
        <a-card title="Active Cards" class="gx-card-full">
          <div class="credit-cards">
            <stripe-card-preview
              v-for="(card, index) of cards"
              :key="index"
              :card="card"
              @remove="removeCard"
              @setDefault="setDefault"
            />
          </div>
        </a-card>
      </a-col>
      <a-col :xs="24" :sm="24" :md="12">
        <a-card
          title="Manage My Payments History"
          class="gx-card-table-full gx-table-responsive"
        >
          <member-billing-history-table />
        </a-card>
      </a-col>
    </a-row>
    <div class="member-welcome-modal">
      <a-modal v-model="visible" :mask-closable="false" class="welcome-modal">
        <a-carousel :after-change="onMemberModalChange" arrows>
          <div>
            <a-row type="flex" align="middle" class="gx-pt-5">
              <a-col :lg="14" :md="24">
                <h1>WELCOME</h1>
                <h2>To Subsapp.com</h2>
                <p>You have successfully logged in as a Member.</p>
              </a-col>
              <a-col :lg="10" :md="24">
                <img src="../../assets/images/slide1.png" alt="slide1" />
              </a-col>
            </a-row>
          </div>
          <div>
            <a-row type="flex" align="middle" class="gx-pt-5">
              <a-col :lg="12" :md="24">
                <h3>As a member you can check your schedule.</h3>
              </a-col>
              <a-col :lg="12" :md="24">
                <img src="../../assets/images/slide2.png" alt="slide2" />
              </a-col>
            </a-row>
          </div>
          <div>
            <a-row type="flex" align="middle" class="gx-pt-4">
              <a-col :lg="12" :md="24">
                <h4>
                  As a member you may join a club and accept/decline club and
                  event invitations.
                </h4>
              </a-col>
              <a-col :lg="12" :md="24">
                <img src="../../assets/images/slide3.png" alt="slide3" />
              </a-col>
            </a-row>
          </div>
          <div>
            <a-row type="flex" align="middle" class="gx-pb-5">
              <a-col :lg="12" :md="24">
                <h3>Subsapp.com helps you check team status.</h3>
              </a-col>
              <a-col :lg="12" :md="24">
                <img src="../../assets/images/slider4.png" alt="slide4" />
              </a-col>
            </a-row>
          </div>
          <div>
            <a-row type="flex" align="middle">
              <a-col :lg="12" :md="24">
                <p>
                  Subsapp.com operates in partnership with Stripe. Meaning
                  payments are easy and secure.
                </p>
              </a-col>
              <a-col :lg="12" :md="24">
                <img src="../../assets/images/slider5.png" alt="slide5" />
              </a-col>
            </a-row>
          </div>
          <div>
            <a-row type="flex" align="middle" class="gx-pt-5">
              <a-col :lg="12" :md="24">
                <h2>Enter your</h2>
                <p>card details and become a member.</p>
              </a-col>
              <a-col :lg="12" :md="24">
                <img src="../../assets/images/slider6.png" alt="slide6" />
              </a-col>
            </a-row>
          </div>
          <template v-if="displayMemberPreviousBtn" #prevArrow>
            <div class="custom-slick-arrow" style="z-index: 1; left: 8px">
              Previous
            </div>
          </template>
          <template v-if="displayMemberNextBtn" #nextArrow>
            <div class="custom-slick-arrow" style="right: 8px">Next</div>
          </template>
        </a-carousel>
        <div
          v-if="!displayMemberNextBtn"
          class="custom-slick-arrow click-btn custom-btn"
          @click="updateIsloggedIn"
        >
          Click here
        </div>
      </a-modal>
    </div>
    <div v-if="inviteModalVisible">
      <a-modal
        v-if="invite"
        v-model="inviteModalVisible"
        title="New Invitation"
        :mask-closable="false"
      >
        <p>
          You have a new invitation from
          <b>{{ invite ? invite.club.club_name : "" }}</b
          >. Would you like to accept it?
        </p>
        <div slot="footer">
          <a-button
            type="primary"
            :disabled="loader"
            @click="
              actionInvitation(invite.club_id, invite.id, 'accept', invite.cmId)
            "
            >Accept</a-button
          >
          <a-button
            :disabled="loader"
            @click="
              actionInvitation(
                invite.club_id,
                invite.id,
                'decline',
                invite.cmId
              )
            "
            >Decline</a-button
          >
        </div>
      </a-modal>
    </div>
    <stripe-new-card-modal
      :visible="newCardModalVisible"
      @token="saveCard"
      @close="closeNewCardModalVisible"
    />
    <a-row>
      <a-col :xs="24" :sm="24" :md="12">
        <a-alert
          v-if="error_msg"
          message="Error"
          description="Something went wrong, we could not add this card."
          type="error"
          closable
          show-icon
          class="margin_top"
          @close="close_alert"
        />
        <div class="add-new-card gx-text-right"></div>
      </a-col>
    </a-row>
  </n-page>
</template>

<script>
import NPage from "@/components/ui/n-page/n-page";
import { AUTH_USER } from "@/store/modules/auth/auth-actions";
import { mapGetters } from "vuex";
import { billingService, authService } from "@/common/api/api.service";
import MemberBillingHistoryTable from "@/components/member-billing/member-billing-history-table/member-billing-history-table";
import StripeNewCardModal from "@/components/billing/stripe-new-card-modal/stripe-new-card-modal";
import StripeCardPreview from "@/components/billing/stripe-card-preview/stripe-card-preview";
// import BankCardPreview from "@/components/billing/bank-card-preview/bank-card-preview";
import notifications from "@/common/notifications/notification.service";
import { clubService } from "@/common/api/api.service";
import { memberService } from "../../common/api/api.service";

export default {
  name: "MemberBilling",
  components: {
    StripeCardPreview,
    StripeNewCardModal,
    MemberBillingHistoryTable,
    // BankCardPreview,
    NPage,
  },
  data() {
    return {
      newCardModalVisible: false,
      cards: [],
      visible: true,
      displayMemberPreviousBtn: false,
      displayMemberNextBtn: true,
      error_msg: false,
      invite: {},
      inviteModalVisible: false,
      loader: false,
    };
  },
  computed: {
    ...mapGetters({
      user: AUTH_USER,
    }),
  },
  mounted() {
    this.getMemberInvitation();
    this.listCards();
    this.fetchBankDetails();
    this.checkModalDisplay();
  },
  methods: {
    actionInvitation(memberId, inviteId, status, cmID) {
      this.loader = true;
      memberService
        .ClubTablelist(memberId, {
          status: status,
          inviteId: inviteId,
          cmID: cmID,
          coach: false,
        })
        .then((resp) => {
          this.loader = false;
          if (resp.data.success) {
            this.inviteModalVisible = false;
          }
        });
    },
    openNewCardModal() {
      this.newCardModalVisible = true;
    },
    onMemberModalChange(index) {
      console.log(index);
      this.displayMemberPreviousBtn = index === 0 ? false : true;
      this.displayMemberNextBtn = index === 5 ? false : true;
    },
    getA: async () => {},
    closeNewCardModalVisible() {
      this.newCardModalVisible = false;
    },
    fetchRoles() {
      clubService.fetchRoles().then((resp) => {
        if (resp.data.success) {
          let userData = JSON.parse(localStorage.getItem("authUserData"));
          userData.user_type = resp.data.result;
          window.localStorage.setItem("authUserData", JSON.stringify(userData));
          this.$store.commit("AUTH_STATE");
        }
      });
    },
    removeCard(tk) {
      billingService
        .removeCard({
          auth: {
            id: this.user.user.user_id,
            type: "user",
          },
          tk: tk,
        })
        .then(() => {
          this.listCards();
        });
    },
    setDefault(tk) {
      billingService
        .setDefaultCard({
          auth: {
            id: this.user.user.user_id,
            type: "user",
          },
          tk: tk,
        })
        .then((resp) => {
          if (resp.data.success) {
            notifications.success("Awesome! Your default card was updated");
          } else {
            notifications.success(
              "Oops, something went wrong. We could updated your default your card."
            );
          }
          this.listCards();
        });
    },
    saveCard(token) {
      billingService
        .saveCard({
          auth: {
            id: this.user.user.user_id,
            type: "user",
          },
          tk: token,
        })
        .then((resp) => {
          if (resp.data.success) {
            notifications.success("Awesome! Your card was added successfully");
          } else {
            notifications.success(
              "Oops, something went wrong. We could not add your card."
            );
          }
          //  this.user.user.no_of_cards = this.user.user.no_of_cards +1 ;
          this.listCards();
          this.fetchRoles();
        });
    },

    redirectStripeAccount() {
      billingService
        .redirectStripe({
          auth: {
            id: this.user.user.user_id,
            type: "club",
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            window.open(resp.data.data, "_blank");
          }
        });
    },

    listCards() {
      billingService
        .listCards({
          auth: {
            id: this.user.user.user_id,
            type: "user",
          },
        })
        .then((resp) => {
          if (resp.data.success) {
            this.cards = resp.data.result;
            this.user.user.no_of_cards = resp.data.no_of_cards;
          }
        });
    },
    fetchBankDetails() {
      this.bankLoading = true;
      billingService
        .listBankDetails({
          auth: {
            id: this.user.user.user_id,
            type: "club",
          },
        })
        .then((resp) => {
          this.bankLoading = false;
          if (resp.data.success) {
            this.bank = resp.data.data;
            console.log("this.bank => ", this.bank);
          }
        });
    },
    checkModalDisplay() {
      let userData = JSON.parse(localStorage.getItem("authUserData"));
      console.log("userData => ", userData);
      this.visible =
        userData.is_logged_in || this.user.user.is_logged_in ? false : true;
    },
    updateIsloggedIn() {
      authService.updateUserLoggedIn().then(() => {
        let userData = JSON.parse(localStorage.getItem("authUserData"));
        userData.is_logged_in = 1;
        window.localStorage.setItem("authUserData", JSON.stringify(userData));
        this.visible = false;
        this.getMemberInvitation();
      });
    },

    getMemberInvitation() {
      authService.getMemberInvitation(false).then((resp) => {
        if (resp.data.success) {
          this.invite = resp.data.result;
          if (this.invite) {
            this.inviteModalVisible = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* .welcome-modal .ant-modal-body {
  padding: 0;
}

.welcome-modal .ant-modal .ant-modal-content {
  padding: 20px;
}

.welcome-modal .ant-modal-body {
  padding: 0;
}

#show-cal {
  color: #2c3e50;
  height: 67vh;
  margin-left: auto;
  margin-right: auto;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 120px;
  height: 36px;
  font-size: 16px;
  color: #38d0f9;
  background-color: #fff;
  text-align: center;
  opacity: 1;
  line-height: 36px;
  border-radius: 20px;
  bottom: 0;
  top: 94%;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.8;
}

.ant-carousel >>> .slick-slide {
  line-height: 100px;
  padding: 50px 0;
}

.ant-carousel >>> .slick-slide h1 {
  color: #fff;
  letter-spacing: 0.05em;
  font-weight: 500;
  line-height: 80px;
  font-size: 64px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
}

.ant-carousel >>> .slick-slide h2 {
  color: #fff;
  font-size: 45px;
  font-weight: 500;
  line-height: 60px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide p {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  margin-top: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
}

.welcome-modal .ant-modal .ant-modal-content {
  padding: 20px;
}

.welcome-modal .ant-modal-body {
  padding: 0;
}

.ant-carousel .click-btn .custom-slick-arrow {
  cursor: pointer;
} */

.welcome-modal .ant-modal-body {
  padding: 0;
}

#show-cal {
  color: #2c3e50;
  height: 67vh;
  margin-left: auto;
  margin-right: auto;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 120px;
  height: 36px;
  font-size: 16px;
  color: #38d0f9;
  background-color: #fff;
  text-align: center;
  opacity: 1;
  line-height: 36px;
  border-radius: 20px;
  bottom: 0;
  top: 94%;
}

.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}

.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.8;
}

.ant-carousel >>> .slick-slide {
  line-height: 100px;
  padding: 50px 0;
}

.ant-carousel >>> .slick-slide h1 {
  color: #fff;
  letter-spacing: 0.05em;
  font-weight: 500;
  line-height: 80px;
  font-size: 64px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
}

.ant-carousel >>> .slick-slide h2 {
  color: #fff;
  font-size: 54px;
  font-weight: 500;
  line-height: 60px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 500;
  line-height: 50px;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
  letter-spacing: 0.05em;
}

.ant-carousel >>> .slick-slide p {
  color: #fff;
  font-size: 30px;
  line-height: 40px;
  margin-top: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  text-shadow: 0px 2px 6px rgb(0 0 0 / 25%);
}

.welcome-modal .ant-modal .ant-modal-content {
  padding: 20px;
}

.welcome-modal .ant-modal-body {
  padding: 0;
}

.ant-carousel .click-btn .custom-slick-arrow {
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 567px) {
  .previous_text {
    display: none;
  }
  .previous_icon {
    display: block !important;
  }

  .next_icon {
    display: block !important;
  }
  .close_button {
    display: block !important;
  }
  .next_text {
    display: none;
  }
  i.next_icon.anticon.anticon-right {
    right: 0;
  }
  i.previous_icon.anticon.anticon-left {
    left: 0;
  }
  .custom-slick-arrow.desktop-slider-icon.slick-arrow.slick-next {
    background: transparent;
    color: white;
    font-size: 23px !important;
  }

  .custom-slick-arrow.desktop-slider-icon.slick-arrow.slick-prev {
    background: transparent;
    color: white;
    font-size: 23px !important;
  }

  .custom-slick-arrow.click-btn.custom-btn {
    bottom: 94%;
    left: 81%;
  }

  .oK_button_text {
    display: none;
  }

  .custom-slick-arrow.click-btn.custom-btn {
    background: transparent;
    color: white;
    font-size: 20px;
  }
  i.close_button.anticon.anticon-close {
    top: 25px;
    right: 45px;
    position: absolute;
  }
  .welcome-modal.ant-modal-root.welcome-modal .ant-modal-content {
    overflow: auto !important;
    height: 90vh !important;
  }
  i.close_button.anticon.anticon-close {
    right: 85px !important;
    position: absolute;
  }
  .ant-carousel .slick-slide img {
    margin-bottom: 0;
    margin-top: 10px;
  }
  .welcome-modal
    .ant-modal
    .ant-modal-content
    .ant-carousel
    .custom-slick-arrow {
    top: 95%;
  }

  i.next_icon.anticon.anticon-right,
  i.previous_icon.anticon.anticon-left {
    position: absolute;
    top: 0px;
  }
}

@media (min-width: 568px) and (max-width: 768px) {
  span.oK_button_text {
    display: none;
  }
}

@media (max-width: 767px) {
  .welcome-modal .ant-modal .ant-modal-content .ant-carousel .slick-slide h2,
  .ant-carousel .slick-slide {
    font-size: 25px;
    line-height: 30px;
  }
  .welcome-modal .ant-modal .ant-modal-content .ant-carousel .slick-slide p {
    font-size: 16px;
    line-height: 22px;
  }
  .welcome-modal .ant-modal .ant-modal-content .ant-carousel .slick-slide h1 {
    line-height: 40px;
    font-size: 34px;
  }
  .welcome-modal
    .ant-modal
    .ant-modal-content
    .ant-carousel
    .custom-slick-arrow {
    top: 96%;
  }
}

.custom-slick-arrow.desktop-slider-icon.slick-arrow.slick-prev {
  left: 0px !important;
}
.custom-slick-arrow.desktop-slider-icon.slick-arrow.slick-next {
  right: 0px !important;
}
.welcome-modal .click-btn.custom-btn {
  height: 30px;
  font-size: 14px;
  top: 16px;
  right: 0;
  line-height: 30px;
}
.ant-carousel .slick-slide img {
  margin-bottom: 20px;
  margin-top: 12px;
}
.ant-carousel .slick-slide h3 {
  font-size: 25px;
}

.previous_icon {
  display: none;
}

.next_icon {
  display: none;
}

.custom-slick-arrow.click-btn.custom-btn {
  background: transparent;
  color: white;
  font-size: 20px;
}
</style>
